import { AppRoutingModule } from './app-routing.module';
import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { AppComponent } from './app.component';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions } from '@angular/material/core';
import * as Sentry from '@sentry/angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ProfileModule } from './modules/profile/profile.module';
import { PageNotFoundModule } from './modules/page-not-found/page-not-found.module';
import { RegisterModule } from './modules/register/register.module';
import { AnalyticsService } from './providers/analytics/analytics.service';
import { AnalyticsComponent } from './modules/analytics/analytics.component';
import { GraphqlModule } from './providers/graphql/graphql.module';
import { LayoutModule } from './modules/layout/layout.module';
import { AuthModule } from './modules/auth/auth.module';
import { ModalModule } from './modules/modal/modal.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { AppointmentModule } from './modules/appointment/appointment.module';
import { ConferenceModule } from './modules/conference/conference.module';
import { CookieModule } from 'ngx-cookie';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EmailModule } from './modules/email/email.module';
import { SettingModule } from './modules/setting/setting.module';
import { TagInputModule } from 'ngx-chips';
import { PreInitConditionModule } from './modules/pre-init-condition/pre-init-condition.module';
import { LogAppointmentModule } from './modules/logging/log-appointment.module';
import { BrandingModule } from './modules/branding/branding.module';
import { PagesModule } from './pages/pages.module';
import { PaymentPayOutModule } from './entities/payment-pay-out/payment-pay-out.module';
import { SpecialityModule } from './entities/speciality/speciality.module';
import { PaymentCountryModule } from './entities/payment-country/payment-country.module';
import { PaymentInfoModule as PaymentInfoEntityModule } from './entities/payment-info/payment-info.module';
import { SurveyModule } from './entities/survey/survey.module';
import { MessageBusModule } from './modules/message-bus/message-bus.module';
import { LegalModule } from './modules/legal/legal.module';
import { LoadingSpinnerModule } from './loading-spinner/loading-spinner.module';
import { UtilsModule } from './modules/utils/utils.module';
import { AppStoreModule } from './providers/store/store.module';
import { ConferenceV2Module } from './modules/conference-v2/conference-v2.module';
import { InstitutionSettingsModule } from './modules/institution-settings/institution-settings.module';
import { CustomErrorHandlerModule } from './modules/custom-error-handler/custom-error-handler.module';
import { environment } from '../environments/environment';
import { TranslateHttpLoaderFactory } from './providers/translate/factories/translate-http-loader.factory';
import { TranslateModule as CustomTranslateModule } from './providers/translate/translate.module';

const rippleConfig: RippleGlobalOptions = {
    disabled: true,
    animation: {
        enterDuration: 0,
        exitDuration: 0
    }
};

// @ts-ignore
// @ts-ignore
@NgModule({
    declarations: [
        AppComponent,
        AnalyticsComponent
    ],
    bootstrap: [AppComponent],
    imports: [BrandingModule,
        BrowserModule,
        BrowserAnimationsModule,
        // @FIXIT:Because module dependency issues we have to import this third party module global. Please fix it
        AppStoreModule,
        AuthModule,
        NgbModule,
        NgxPermissionsModule.forRoot(),
        RegisterModule,
        PageNotFoundModule,
        ProfileModule,
        AppointmentModule,
        ConferenceModule,
        ConferenceV2Module,
        AppRoutingModule,
        LayoutModule,
        GraphqlModule,
        ModalModule,
        EmailModule,
        SettingModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: TranslateHttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        CookieModule.forRoot(),
        TagInputModule,
        PreInitConditionModule,
        LogAppointmentModule,
        PagesModule,
        SpecialityModule,
        PaymentInfoEntityModule,
        PaymentCountryModule,
        PaymentPayOutModule,
        SurveyModule,
        MessageBusModule,
        LegalModule,
        LoadingSpinnerModule,
        UtilsModule,
        InstitutionSettingsModule,
        CustomErrorHandlerModule.forRoot(environment.production),
        CustomTranslateModule
    ],
    providers: [
        AnalyticsService,
        Title,
        { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: rippleConfig },
        provideHttpClient(withInterceptorsFromDi()),
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({ showDialog: false })
        }
    ]
})

export class AppModule {
}
