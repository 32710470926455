
import { Action } from '@ngrx/store';
import { EmailTypeAppointmentRequestAccept } from '../types/appointment-request-accept';

export interface EmailAction extends Action {
    readonly type: string;
    payload: EmailTypeAppointmentRequestAccept | number | null;
    errors: string[] | null;
}

export const EMAIL_SEND_APPOINTMENT_REQUEST_ACCEPT_SUCCESSFUL = 'EMAIL_SEND_APPOINTMENT_REQUEST_ACCEPT_SUCCESSFUL';
export class EmailSendAppointmentRequestAcceptSuccessfulAction implements EmailAction {
    readonly type = EMAIL_SEND_APPOINTMENT_REQUEST_ACCEPT_SUCCESSFUL;
    errors = null;
    payload = null;
}

export const EMAIL_SEND_APPOINTMENT_REQUEST_REJECT_SUCCESSFUL = 'EMAIL_SEND_APPOINTMENT_REQUEST_REJECT_SUCCESSFUL';
export class EmailSendAppointmentRequestRejectSuccessfulAction implements EmailAction {
    readonly type = EMAIL_SEND_APPOINTMENT_REQUEST_REJECT_SUCCESSFUL;
    errors = null;
    payload = null;
}
export const EMAIL_SEND_ONE_TIME_APPOINTMENT_SUCCESSFUL = 'EMAIL_SEND_ONE_TIME_APPOINTMENT_SUCCESSFUL';
export class EmailSendOneTimeAppointmentSuccessfulAction implements EmailAction {
    readonly type = EMAIL_SEND_ONE_TIME_APPOINTMENT_SUCCESSFUL;
    errors = null;
    payload = null;
}

export const EMAIL_SEND_CANCEL_APPOINTMENT_SUCCESSFUL = 'EMAIL_SEND_CANCEL_APPOINTMENT_SUCCESSFUL';
export class EmailCancelAppointmentSuccessfulAction implements EmailAction {
    readonly type = EMAIL_SEND_CANCEL_APPOINTMENT_SUCCESSFUL;
    errors = null;
    payload = null;
}

export const EMAIL_SEND_FAILED = 'EMAIL_SEND_FAILED';
export class EmailSendFailedAction implements EmailAction {
    readonly type = EMAIL_SEND_FAILED;
    payload = null;
    constructor(public errors: string[]) {}
}
