import { filter } from "rxjs/operators";
import { Component, OnInit } from "@angular/core";
import { CustomTranslateService } from "./providers/translate/services/custom-translate.service";
import { AppStateService } from "./providers/store/app-state.service";
import { BrandingService } from "./modules/branding/providers/branding.service";
import { Title } from "@angular/platform-browser";
import { SentryService } from "./providers/sentry/sentry.service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent implements OnInit {
    constructor(
        private customTranslateService: CustomTranslateService,
        private appState: AppStateService,
        private titleService: Title,
        private branding: BrandingService,
        private readonly sentryService: SentryService
    ) {
        this.sentryService.init();
        this.customTranslateService.initTranslate();
        this.branding.initBranding();
        this.branding
            .getBrandingSettingsFromStore()
            .pipe(filter((e) => e !== null && e !== undefined))
            .subscribe(
                (res) => {
                    if (res.title) {
                        this.titleService.setTitle(res.title);
                    }
                },
                (err) => console.log(err)
            );
    }

    ngOnInit(): void {
        this.appState.initState();
    }
}
