import { Observable, of } from "rxjs";
import { catchError, filter, map, mapTo, mergeMap } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { EmailService } from "../providers/email.service";
import { Action } from "@ngrx/store";
import {
    APPOINTMENT_REQUEST_ACCEPT_SUCCESSFUL,
    APPOINTMENT_REQUEST_REJECT_SUCCESSFUL,
    AppointmentRequestAcceptSuccessfulAction,
    AppointmentRequestRejectSuccessfulAction,
} from "../../appointment-request/store/appointment-request.action";
import { AppointmentRequest } from "../../appointment-request/store/appointment-request.entity";
import { EmailTypeAppointmentRequestAccept } from "../types/appointment-request-accept";
import {
    EmailCancelAppointmentSuccessfulAction,
    EmailSendAppointmentRequestAcceptSuccessfulAction,
    EmailSendAppointmentRequestRejectSuccessfulAction,
    EmailSendFailedAction,
    EmailSendOneTimeAppointmentSuccessfulAction,
} from "./email.action";
import { EmailTypeAppointmentRequestReject } from "../types/appointment-request-reject";
import {
    APPOINTMENT_CREATE_TAN_APPOINTMENT_COMPLETED,
    CreateTanAppointmentCompletedAction,
    APPOINTMENT_CANCEL_COMPLETED,
    CancelAppointmentCompletedAction,
} from "../../appointment/store/one-time-appointment/appointment.action";
import { EmailTypeOneTimeAppointment } from "../types/one-time-appointment";
import { CreateTanAppointmentDTO } from "../../appointment/providers/create-tan.types";

@Injectable()
export class EmailEffects {
    constructor(
        private actions$: Actions,
        private emailService: EmailService
    ) {}

    doSendAppointmentRequestAcceptMail$: Observable<Action[] | Action> =
        createEffect(() =>
            this.actions$.pipe(
                ofType(APPOINTMENT_REQUEST_ACCEPT_SUCCESSFUL),
                map(
                    (action: AppointmentRequestAcceptSuccessfulAction) =>
                        action.payload
                ),
                mergeMap((appointmentRequest: AppointmentRequest) =>
                    this.emailService
                        .prepareAppointmentRequestAcceptPayload(
                            appointmentRequest
                        )
                        .pipe(
                            mergeMap((v: EmailTypeAppointmentRequestAccept) =>
                                this.emailService.sendAppointmentRequestAcceptEmail(
                                    v
                                )
                            ),
                            mapTo(
                                new EmailSendAppointmentRequestAcceptSuccessfulAction()
                            ),
                            catchError((err) =>
                                of(new EmailSendFailedAction(err))
                            )
                        )
                )
            )
        );

    doSendAppointmentRequestRejectMail$: Observable<Action[] | Action> =
        createEffect(() =>
            this.actions$.pipe(
                ofType(APPOINTMENT_REQUEST_REJECT_SUCCESSFUL),
                map(
                    (action: AppointmentRequestRejectSuccessfulAction) =>
                        action.payload
                ),
                mergeMap((appointmentRequest: AppointmentRequest) =>
                    this.emailService
                        .prepareAppointmentRequestRejectPayload(
                            appointmentRequest
                        )
                        .pipe(
                            mergeMap((v: EmailTypeAppointmentRequestReject) =>
                                this.emailService.sendAppointmentRequestRejectEmail(
                                    v
                                )
                            ),
                            mapTo(
                                new EmailSendAppointmentRequestRejectSuccessfulAction()
                            ),
                            catchError((err) =>
                                of(new EmailSendFailedAction(err))
                            )
                        )
                )
            )
        );

    doSendOneTimeAppointmentMail$: Observable<Action[] | Action> = createEffect(
        () =>
            this.actions$.pipe(
                ofType(APPOINTMENT_CREATE_TAN_APPOINTMENT_COMPLETED),
                map(
                    (action: CreateTanAppointmentCompletedAction) =>
                        action.payload
                ),
                filter(
                    (dto: CreateTanAppointmentDTO) =>
                        dto.profile.email !== null &&
                        dto.profile.email !== "" &&
                        dto.profile.email !== undefined
                ),
                mergeMap((dto: CreateTanAppointmentDTO) =>
                    this.emailService
                        .prepareOnetimeAppointmentPayload(dto)
                        .pipe(
                            mergeMap((v: EmailTypeOneTimeAppointment) =>
                                this.emailService.sendOneTimeAppointmentEmail(v)
                            ),
                            mapTo(
                                new EmailSendOneTimeAppointmentSuccessfulAction()
                            ),
                            catchError((err) =>
                                of(new EmailSendFailedAction(err))
                            )
                        )
                )
            )
    );

    doSendCancelAppointmentMail$ = createEffect(() =>
        this.actions$.pipe(
            ofType(APPOINTMENT_CANCEL_COMPLETED),
            map((action: CancelAppointmentCompletedAction) => action.payload),
            filter((dto) => !!dto.profile.email),
            mergeMap((dto) =>
                this.emailService.prepareCancelAppointmentPayload(dto).pipe(
                    mergeMap((payload) =>
                        this.emailService.sendCancelAppointmentEmail(payload)
                    ),
                    mapTo(new EmailCancelAppointmentSuccessfulAction()),
                    catchError((err) => of(new EmailSendFailedAction(err)))
                )
            )
        )
    );
}
