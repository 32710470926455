import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Store } from "@ngrx/store";
import { ProfileService } from "../../profile/providers/profile.service";
import { AppointmentRequest } from "../../appointment-request/store/appointment-request.entity";
import { EmailTypeAppointmentRequestAccept } from "../types/appointment-request-accept";
import { environment } from "../../../../environments/environment";
import { EmailTypeAppointmentRequestReject } from "../types/appointment-request-reject";
import { AppState } from "../../../providers/store/app.state";
import {
    DATE_TIME_FORMAT_EXPORT,
    TimeHelperService,
} from "../../utils/time-helpers/time-helper.service";
import { EmailTypeOneTimeAppointment } from "../types/one-time-appointment";
import { getGenderNameById } from "../../utils/genders";
import { CreateTanAppointmentDTO } from "../../appointment/providers/create-tan.types";
import { EmailTypeAppointmentRequestCancel } from "../types/appointment-request-cancel";
import { MultiUserAppointment } from "../../appointment/store/appointment/multi-user-appointment.entity";
import { Profile } from "../../profile/store/profile.entity";
import { AppointmentInfoService } from "../../appointment/providers/appointment-info.service";
import { CancelAppointmentDTO } from "../../appointment/providers/cancel-appointment.type";

@Injectable()
export class EmailService {
    constructor(
        private http: HttpClient,
        private profileService: ProfileService,
        private appointmentInfoService: AppointmentInfoService,
        private store: Store<AppState>
    ) {}

    public prepareAppointmentRequestAcceptPayload(
        appointmentRequest: AppointmentRequest
    ): Observable<EmailTypeAppointmentRequestAccept> {
        return this.profileService.getCurrentProfileDataObserver().pipe(
            map((response) => {
                const startDateTime = TimeHelperService.getMomentDateTime(
                    appointmentRequest.appointmentStartDateTime
                ).format(DATE_TIME_FORMAT_EXPORT);

                const payload: EmailTypeAppointmentRequestAccept = {
                    type: "bookable-appointment-accepted",
                    data: {
                        doctor_salutation: response.salutation,
                        doctor_title: response.title,
                        doctor_first_name: response.firstName,
                        doctor_last_name: response.lastName,
                        doctor_telephone_number: response.telephoneNumber,
                        doctor_public_profile: response.publicProfileUrl,
                        branding: response.branding,
                        patient_email: appointmentRequest.email,
                        patient_salutation: getGenderNameById(
                            appointmentRequest.salutation.trim()
                        ),
                        patient_first_name: appointmentRequest.firstName,
                        patient_last_name: appointmentRequest.lastName,
                        appointment_tan: appointmentRequest.tan,
                        appointment_confirmation_comment:
                            appointmentRequest.confirmationComment,
                        appointment_start_date_time: startDateTime,
                    },
                };

                return payload;
            })
        );
    }

    public sendAppointmentRequestAcceptEmail(
        payload: EmailTypeAppointmentRequestAccept
    ): Observable<any> {
        return this.http.post(environment.emailEndPoint, payload);
    }

    public prepareAppointmentRequestRejectPayload(
        appointmentRequest: AppointmentRequest
    ): Observable<EmailTypeAppointmentRequestReject> {
        return this.profileService.getCurrentProfileDataObserver().pipe(
            map((response) => {
                const startDateTime = TimeHelperService.getMomentDateTime(
                    appointmentRequest.appointmentStartDateTime
                ).format(DATE_TIME_FORMAT_EXPORT);

                const payload: EmailTypeAppointmentRequestReject = {
                    type: "bookable-appointment-rejected",
                    data: {
                        doctor_salutation: response.salutation,
                        doctor_title: response.title,
                        doctor_first_name: response.firstName,
                        doctor_last_name: response.lastName,
                        doctor_telephone_number: response.telephoneNumber,
                        doctor_public_profile: response.publicProfileUrl,
                        branding: response.branding,
                        patient_email: appointmentRequest.email,
                        patient_salutation: getGenderNameById(
                            appointmentRequest.salutation.trim()
                        ),
                        patient_first_name: appointmentRequest.firstName,
                        patient_last_name: appointmentRequest.lastName,
                        appointment_confirmation_comment:
                            appointmentRequest.confirmationComment,
                        appointment_start_date_time: startDateTime,
                    },
                };

                return payload;
            })
        );
    }

    public sendAppointmentRequestRejectEmail(
        payload: EmailTypeAppointmentRequestReject
    ): Observable<any> {
        return this.http.post(environment.emailEndPoint, payload);
    }

    public prepareOnetimeAppointmentPayload(
        dto: CreateTanAppointmentDTO
    ): Observable<EmailTypeOneTimeAppointment> {
        return this.profileService.getCurrentProfileDataObserver().pipe(
            map((response) => {
                const startDateTime = TimeHelperService.getMomentDateTime(
                    dto.appointment.startDateTime
                ).format(DATE_TIME_FORMAT_EXPORT);

                const payload: EmailTypeOneTimeAppointment = {
                    type: "one-time-appointment",
                    data: {
                        doctor_salutation: response.salutation,
                        doctor_title: response.title,
                        doctor_first_name: response.firstName,
                        doctor_last_name: response.lastName,
                        doctor_telephone_number: response.telephoneNumber,
                        doctor_email: response.email,
                        doctor_public_profile: response.publicProfileUrl,
                        branding: response.branding,
                        patient_email: dto.profile.email,
                        patient_salutation: getGenderNameById(
                            dto.profile.salutation.trim()
                        ),
                        patient_first_name: dto.profile.firstName,
                        patient_last_name: dto.profile.lastName,
                        appointment_tan: dto.appointment.tan,
                        appointment_tan_message: dto.getEmailMessage(),
                        appointment_start_date_time: startDateTime,
                    },
                };
                return payload;
            })
        );
    }

    public sendOneTimeAppointmentEmail(
        payload: EmailTypeOneTimeAppointment
    ): Observable<any> {
        return this.http.post(
            environment.emailEndPointOneTimeAppointment,
            payload
        );
    }

    public prepareCancelAppointmentPayload({
        appointment,
        profile,
    }: CancelAppointmentDTO) {
        return this.appointmentInfoService
            .loadMUCParticipants(appointment, profile.id)
            .pipe(
                map((participants) => {
                    const startDateTime = TimeHelperService.getMomentDateTime(
                        appointment.startDateTime
                    ).format(DATE_TIME_FORMAT_EXPORT);
                    const endDateTime = TimeHelperService.getMomentDateTime(
                        appointment.endDateTime
                    ).format(DATE_TIME_FORMAT_EXPORT);

                    const payload: EmailTypeAppointmentRequestCancel = {
                        type: "muc-appointment-cancel",
                        data: {
                            doctor_salutation: profile.salutation,
                            doctor_title: profile.title,
                            doctor_first_name: profile.firstName,
                            doctor_last_name: profile.lastName,
                            doctor_telephone_number: profile.telephoneNumber,
                            doctor_email: profile.email,
                            doctor_public_profile: profile.publicProfileUrl,
                            branding: profile.branding,
                            patient_email: profile.email,
                            patient_salutation: profile.salutation.trim(),
                            patient_first_name: profile.firstName,
                            patient_last_name: profile.lastName,
                            appointment_tan: appointment.tan ?? "",
                            appointment_message: appointment.appointmentMessage,
                            appointment_start_date_time: startDateTime,
                            appointment_end_date_time: endDateTime,
                            appointment_period: appointment.period,
                            appointment_topic: appointment.topic,
                            total_participants: participants.length,
                            participants: participants.map((participant) => ({
                                patientEmail: participant.email,
                                patientSalutation:
                                    participant.salutation.trim(),
                                patientFirstName: participant.firstName,
                                patientLastName: participant.lastName,
                                appointmentTan: appointment.tan ?? "",
                                appointmentTanMessage:
                                    appointment.appointmentMessage,
                            })),
                        },
                    };
                    return payload;
                })
            );
    }

    public sendCancelAppointmentEmail(
        payload: EmailTypeAppointmentRequestCancel
    ) {
        return this.http.post<void>(environment.emailEndPoint, payload);
    }
}
